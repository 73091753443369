/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from "react";
import {adminHost, host} from "../../http";
import icons from "../../assets/icons/coin_map.json";
import info from "../../assets/images/info.svg";
import SeeMore from "../../assets/images/seemore.svg";
import Electro from "../../assets/images/electro.svg";
import oko from "../../assets/images/oko.svg";
import swapmini from "../../assets/images/swapmini.svg";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import Close from "../../assets/images/close.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../modals/Alert";
import ManualUpdate from "../modals/ManualUpdate";
import "../../assets/css/all.css"
import InfoAlert from "../modals/InfoAlert"
import { Context } from "../..";

const SwapsS = ({ fixed, float }) => {
  const { admin: { options } } = useContext(Context);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertText, setAlertText] = useState(null);
  const [isManualUpdOpen, setIsManualUpdOpen] = useState(false);
  const [itemToUpd, setItemToUpd] = useState(null);
  const [statusF, setStatusF] = useState(null);
  const [tokenF, setTokenF] = useState("");
  const [dateF, setDateF] = useState(null);
  const [networkF, setNetworkF] = useState(null);

  const [infoAlert, setInfoAlert] = useState(false)
  const [infoAlertText, setInfoAlertText] = useState('')
  const [infoRefundText, setInfoRefundText] = useState('')

  const [timeSwitch, setTimeSwitch] = useState({});

  //EXCHANGES TABLE
  const [exchangesList, setExchangesList] = useState(null);
  const [exchCurrPage, setExchCurrPage] = useState(1);
  const [exchTotalPages, setExchTotalPages] = useState(null);
  const [filterHandle, setFilterHandle] = useState(false);

  const [filterKey, setFilterKey] = useState("");

  const statuses = {
    0: "",
    1: "WAITING",
    2: "CONFIRMATION",
    3: "TRANSFERRING",
    4: "EXCHANGING",
    5: "SENDING",
    6: "SUCCESS",
    7: "OVERDUE",
    8: "FROZEN",
    9: "PROBLEMATIC",
    10: "REFUNDED",
  };

  const paginatorRender = () => {
    const buttons = [];
    if (exchTotalPages <= 10) {
      for (let i = 1; i <= exchTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${exchCurrPage === i ? "active_page" : ""}`} onClick={() => setExchCurrPage(i)}>
            {i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${exchCurrPage === i ? "active_page" : ""}`} onClick={() => setExchCurrPage(i)}>
            {i}
          </div>
        );
      }
      buttons.push(
        <div key={0} className={`page-button margin`}>
          ...
        </div>
      );
      for (let i = exchTotalPages - 4; i <= exchTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${exchCurrPage === i ? "active_page" : ""}`} onClick={() => setExchCurrPage(i)}>
            {i}
          </div>
        );
      }
    }

    return buttons;
  };

  const formatedLostTime = (diff) => {
    const hours = Math.abs(Math.floor(diff / (1000 * 60 * 60)));
    const minutes = Math.abs(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)));
    const seconds = Math.abs(Math.floor((diff % (1000 * 60)) / 1000));
    const formattedDiff = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedDiff;
  };

  const stopExchange = (id) => {
    adminHost
      .post(`/api/stop_exchange/`, {
        unique_id: id,
      })
      .then((response) => {
        setFilterHandle((prev) => !prev);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    const params = {};
    if (filterKey) {
      params.search = filterKey;
    } else {
      if (statusF) params.status = statusF;
      if (tokenF) params.token = tokenF;
      if (dateF) params.date = dateF;
    }
    adminHost
      .get(`/api/exchanges/?${new URLSearchParams(params)}`)
      .then((response) => {
        const newTotalPages = Math.ceil(response.data.count / 10);
        setExchTotalPages(newTotalPages);
        if (exchCurrPage >= newTotalPages) {
          setExchCurrPage(1);
        }
        if (newTotalPages <= 1) {
          setExchangesList(response.data);
          return;
        }
        params.page = newTotalPages;
        adminHost
          .get(`/api/exchanges/?${new URLSearchParams(params)}`)
          .then((res) => {
            setExchCurrPage(1);
            setExchangesList(res.data);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }, [filterHandle]);

  useEffect(() => {
    const params = {};
    if (exchTotalPages && exchCurrPage) {
      if (filterKey) {
        params.search = filterKey;
      } else {
        if (statusF) params.status = statusF;
        if (tokenF) params.token = tokenF;
        if (dateF) params.date = dateF;
      }
      params.page = Math.min(exchTotalPages - exchCurrPage + 1, exchTotalPages);

      adminHost
        .get(`/api/exchanges/?${new URLSearchParams(params)}`)
        .then((response) => {
          setExchangesList(response.data); // Destructure response.data
          setExchTotalPages(Math.ceil(response.data.count / 10));
        })
        .catch((e) => console.error(e));
    }
  }, [exchCurrPage]);

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  console.log(options)

  return (
    <div className="right_block">
      <ul className="navbar">
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Status"
            value={statuses[statusF]}
            onChange={(e) => {
              setStatusF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {statusF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setStatusF(0)} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            <button
              className="dropbtn"
              onClick={() => {
                setStatusF(0);
                setFilterKey("");
              }}
            >
              All
            </button>
            <button
              className="Waiting dropbtn"
              onClick={() => {
                setStatusF(1);
                setFilterKey("");
              }}
            >
              Waiting
            </button>
            <button
              className="Confirmation dropbtn"
              onClick={() => {
                setStatusF(2);
                setFilterKey("");
              }}
            >
              Confirmation
            </button>
            <button
              className="Transferring dropbtn"
              onClick={() => {
                setStatusF(10);
                setFilterKey("");
              }}
            >
              Transferring
            </button>
            <button
              className="Exchanging dropbtn"
              onClick={() => {
                setStatusF(3);
                setFilterKey("");
              }}
            >
              Exchanging
            </button>
            <button
              className="Sending dropbtn"
              onClick={() => {
                setStatusF(4);
                setFilterKey("");
              }}
            >
              Sending
            </button>
            <button
              className="Success dropbtn"
              onClick={() => {
                setStatusF(5);
                setFilterKey("");
              }}
            >
              Success
            </button>
            <button
              className="Overdue dropbtn"
              onClick={() => {
                setStatusF(6);
                setFilterKey("");
              }}
            >
              Overdue
            </button>
            <button
              className="Frozen dropbtn"
              onClick={() => {
                setStatusF(7);
                setFilterKey("");
              }}
            >
              Frozen
            </button>
            <button
              className="Problematic dropbtn"
              onClick={() => {
                setStatusF(8);
                setFilterKey("");
              }}
            >
              Problematic
            </button>
            <button
              className="Refunded dropbtn"
              onClick={() => {
                setStatusF(9);
                setFilterKey("");
              }}
            >
              Refunded
            </button>
            <button
              className="Overdue dropbtn"
              onClick={() => {
                setStatusF(11);
                setFilterKey("");
              }}
            >
              Stopped
            </button>
          </ul>
        </li>
        <li className="hover ">
          <input
            type="text"
            className="select"
            placeholder="Currency"
            value={tokenF}
            onChange={(e) => {
              setTokenF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {tokenF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setTokenF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone currency_style">
            {options
              ?.filter((el) => el?.value?.includes(tokenF))
              ?.map((opt) => {
                const crypto_icon = icons[opt?.value.toUpperCase()] ? icons[opt?.value.toUpperCase()] : icons["BTC"];
                return (
                  <li
                    onClick={() => {
                      setTokenF(opt?.value);
                      setNetworkF("");
                      setFilterKey("");
                    }}
                  >
                    <p style={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
                      <img src={crypto_icon} width={20} style={{ borderRadius: "5px", marginRight: "10px" }} alt={opt?.value} />
                      {opt?.value}
                    </p>
                  </li>
                );
              })}
          </ul>
        </li>
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Network"
            value={networkF}
            onChange={(e) => {
              setNetworkF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {networkF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setNetworkF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            {tokenF ? (
              options
                ?.find((opt) => opt?.value === tokenF)
                ?.networks?.withdraw?.map((net) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setNetworkF(net);
                        setFilterKey("");
                      }}
                    >
                      {net}
                    </li>
                  );
                })
            ) : (
              <li>Empty list</li>
            )}
          </ul>
        </li>
        <li>
          <DatePicker
            isClearable
            className="datepicker"
            placeholderText="Select date"
            dateFormat="dd-MM-yyyy"
            selected={dateF}
            onChange={(date) => {
              if (date === null) {
                setDateF("");
              } else {
                setDateF("" + new Date(date).toISOString().split("T")[0]);
                setFilterKey("");
              }
            }}
          />
        </li>
        <li style={{ position: "relative" }}>
          <input
            className="Filterbykey"
            placeholder="Search by ID"
            value={filterKey}
            type="text"
            onChange={(e) => {
              setFilterKey(e.target.value);
              setTokenF("");
              setNetworkF("");
              setStatusF(0);
              setDateF(null);
            }}
          />
          {filterKey ? <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setFilterKey("")} alt="" /> : null}
        </li>
        <button id="admin-pnl-btn" onClick={() => setFilterHandle(!filterHandle)}>
          <img src={Electro} alt="" />
        </button>
      </ul>
      <div className="content_block">
        <table className="table">
          <tr className="th_names">
            <th className="radius">ID</th>
            <th>Status</th>
            <th>Time</th>
            <th>Info</th>
            <th>Volume</th>
            <th>Type</th>
            <th>Pairs</th>
            <th>Profit</th>
            <th className="radius2">Partner profit</th>
          </tr>

          {exchangesList?.results
            ?.slice()
            ?.reverse()
            ?.map((item) => {
              console.log(item)
              return (
                <tr className="content_table">
                  <td>
                    <div className="url_block">
                      <div
                        className="Url copy-element"
                        onClick={() => {
                          navigator.clipboard.writeText(item?.unique_id);
                          setAlertIsOpen(true);
                        }}
                      >
                        {item?.unique_id}
                      </div>
                      <div className="market">
                        {item?.referral_username ? item?.referral_username : 'Swaponix.io'} ({item?.fixed ? fixed : float}%)
                      </div>
                    </div>
                  </td>
                  <td className="padding">
                    <div className={item?.status && item.status in statuses ? "status status" + statuses[item?.status].toUpperCase() : "status"}>
                      {item?.status in statuses ? statuses[item.status] : "UNKNOWN"}
                    </div>
                  </td>
                  <td className="padding">
                    <div
                      className="time copy-element"
                      onClick={() => {
                        setTimeSwitch((prev) => ({ ...prev, [item.id]: !prev[item.id] }));
                      }}
                    >
                      {timeSwitch[item.id] ? (
                        <>
                          {new Date(item?.start_time).toLocaleString("uk-UA").split(".").join("/")}
                          <br></br>
                          {item?.end_time ? new Date(item?.end_time).toLocaleString("uk-UA").split(".").join("/") : null}
                        </>
                      ) : item?.end_time ? (
                        formatedLostTime(new Date(item?.end_time) - new Date(item?.start_time))
                      ) : (
                        formatedLostTime(new Date() - new Date(item?.start_time))
                      )}
                      <img сlassName="oko" width={12} style={{ marginLeft: "5px" }} src={oko} alt="" />
                    </div>
                  </td>

                      <td className="padding info" style={{cursor: 'pointer'}}>
                        <div
                        onClick={() => {
                          setInfoAlert(true)
                          setInfoAlertText(`${item.support_email != "" ? item.support_email : "No info"}`)
                          setInfoRefundText(`RefundAddress: ${item.withdraw_refund != "" ? item.withdraw_refund : "No info"}`)
                        }}
                        >
                          <div className="infoBlock">
                          <img src={info} className="infoSvg"/>
                          </div>
                        </div>
                      </td>

                  <td className="padding">
                    <div className="volume upcolor">{item.volume} USDT</div>
                  </td>
                  <td className="padding">
                    <div className="type">{item?.fixed ? "Fixed" : "Float"}</div>
                  </td>
                  <td className="padding">
                    <div className="pairs">
                      <div className="leftpair">
                        {options?.find((el) => el?.value === item?.token1)?.platform}
                        <div className="flex-block copy-element">
                          {item?.exp_token1_amount} {item?.token1?.toUpperCase()} {"(" + item?.token1_network?.toUpperCase() + ")"}
                          <img
                            className="oko"
                            width={12}
                            src={oko}
                            alt=""
                            onClick={() => {
                              setAlertIsOpen(true);
                              setAlertText(
                                `Deposit ID: ${item?.deposit_id ?? "Missing"}_Expected Deposit: ${item?.exp_token1_amount ?? 0} ${item?.token1}_Actual Deposit: ${item?.token1_amount ?? 0} ${
                                  item?.token1
                                }_User Receive: ${item?.token1_amount - item?.token1_amount * (item?.fixed ? fixed / 100 : float / 100)} ${item?.token1}`
                              );
                            }}
                          />
                        </div>
                        <div
                          className="flex-block copy-element"
                          onClick={() => {
                            navigator.clipboard.writeText(item.node_deposit_address ? item.node_deposit_address : item.deposit_address
                            );
                            setAlertIsOpen(true);
                          }}
                        >
                          {(item.node_deposit_address ? item.node_deposit_address : item.deposit_address).substr(0, 4) + "..." + (item.node_deposit_address ? item.node_deposit_address : item.deposit_address).substr((item.node_deposit_address ? item.node_deposit_address : item.deposit_address).length - 4, (item.node_deposit_address ? item.node_deposit_address : item.deposit_address).length)}
                          <img className="oko" width={10} src={oko} alt="" />
                        </div>
                      </div>
                      <img className="swapmini" src={swapmini} alt="" />
                      <div className="rightpair">
                        {options?.find((el) => el?.value === item?.token2)?.platform}
                        <div className="flex-block copy-element">
                          {item?.token2_amount ? item?.token2_amount: item?.exp_token2_amount} {item?.token2?.toUpperCase()} {"(" + item?.token2_network?.toUpperCase() + ")"}
                          <img
                            className="oko"
                            width={12}
                            src={oko}
                            alt=""
                            onClick={() => {
                              setAlertIsOpen(true);
                              setAlertText(
                                `Withdrawals ID: ${item?.withdraw_id ?? "Missing"}_Expected Withdrawals: ${item?.exp_token2_amount ?? 0} ${item?.token2}_Actual Withdrawals: ${
                                  item?.token2_amount ?? 0
                                } ${item?.token2}_User Receive: ${item?.token2_amount - item?.token2_amount * (item?.fixed ? fixed / 100 : float / 100)} ${item?.token2}`
                              );
                            }}
                          />
                        </div>
                        <div
                          className="flex-block copy-element"
                          onClick={() => {
                            navigator.clipboard.writeText(item?.address);
                            setAlertIsOpen(true);
                          }}
                        >
                          {item?.address?.substr(0, 4) + "..." + item?.address?.substr(item?.address?.length - 4, item?.address?.length)}
                          <img className="oko" width={10} src={oko} alt="" />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="padding">
                    <div className="profit upcolor">
                      {item?.profit ?? 0} USDT ({item?.fixed ? fixed : float}
                      %)
                    </div>
                  </td>
                  <td className="padding">
                    <div className="part_profit upcolor">
                      {/* {+(options?.find((el) => el?.value === item?.token1)?.price * item?.exp_token1_amount * (item?.fixed ? fixed / 100 : float / 100)).toFixed(6)} USDT ({item?.fixed ? fixed : float}%) */}
                      {item?.partner_profit ?? 0} USDT ({0.0}%)
                    </div>
                  </td>
                  <div className="item_control">
                    {
                    item?.status <= 2 &&
                    item?.is_stopped === false ? (
                      <button
                        style={{ background: "#b72021" }}
                        onClick={() => {
                          stopExchange(item?.unique_id);
                        }}
                      >
                        Stop Exchange
                      </button>
                    ) : null}
                    <button
                      style={{ background: "#2c57ec" }}
                      onClick={() => {
                        setItemToUpd(item);
                        setIsManualUpdOpen(true);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </tr>
              );
            })}
          {renderEmptyRows(exchangesList?.results?.length)}
        </table>
        <div className="slidepages" style={{ userSelect: "none" }}>
          <div
            className="arrowleft margin"
            onClick={() => {
              exchangesList?.next && setExchCurrPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          {paginatorRender()}
          <div
            className="arrowright margin"
            onClick={() => {
              exchangesList?.previous && setExchCurrPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
      {alertIsOpen ? (
        <Alert
          onClose={() => {
            setAlertIsOpen(false);
            setAlertText(null);
          }}
          text={alertText}
        />
      ) : null}
      {isManualUpdOpen ? (
        <ManualUpdate
          onClose={() => {
            setIsManualUpdOpen(false);
            setExchCurrPage(prev => prev)
          }}
          item={itemToUpd}
          statuses={Object.fromEntries(Object.entries(statuses).slice(1))}
        />
      ) : null}
      {infoAlert ? 
        <InfoAlert 
        text={infoAlertText}
        refundText={infoRefundText}
        onClose={
          () => {
            setInfoAlert(false)
            setInfoAlertText('')
            setInfoRefundText('')
          }
        }/> : 
      null}
    </div>
  );
};

export default SwapsS;
