import React, { useState, useEffect } from "react";
import {adminHost} from "../../http";
import Close from "../../assets/images/close.svg";
import Alert from "./Alert";

const ManualUpdate = ({onClose, item, statuses }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false)
  const [status, setStatus] = useState(item?.status || null);
  const [depId, setDepId] = useState(null);
  const [nodeDepId, setNodeDepId] = useState(null);
  const [withId, setWithId] = useState(null);
  const [buyId, setBuyId] = useState(null);
  const [sellId, setSellId] = useState(null);
  const [changesSaved, setChangesSaved] = useState(false);
  const [statusList, setStatusList] = useState(null);
  const [fieldsChanged, setFieldsChanged] = useState({
    status: false,
    depId: false,
    nodeDepId: false,
    withId: false,
    buyId: false,
    sellId: false
  });

  useEffect(() => {
    if (changesSaved === true) {
      setTimeout(() => {
        setChangesSaved(false);
        setStatus(null);
        setDepId(null);
        setWithId(null);
        setBuyId(null);
        setSellId(null);
        setStatusList(null);
        setNodeDepId(null)
        setFieldsChanged({
          status: false,
          depId: false,
          nodeDepId: false,
          withId: false,
          buyId: false,
          sellId: false
        });
        onClose()
      }, 1000)
    }
  }, [changesSaved])

  useEffect(() => {
    setStatus(item?.status)
    setStatusList(Object.entries(statuses)?.map(([value, label]) => ({ value, label })))
    setDepId(item?.deposit_id);
    setWithId(item?.withdraw_id);
    setBuyId(item?.order_buy_id);
    setSellId(item?.order_sell_id);
    setNodeDepId(item.node_deposit_id)
  }, [statuses, item])

  const saveChanges = (id) => {
    const payload = {};
    
    if (fieldsChanged.depId) payload.deposit_id = depId || null;
    if (fieldsChanged.nodeDepId) payload.node_deposit_id = nodeDepId || null;
    if (fieldsChanged.buyId) payload.order_buy_id = buyId || null;
    if (fieldsChanged.sellId) payload.order_sell_id = sellId || null;
    if (fieldsChanged.withId) payload.withdraw_id = withId || null;
    if (fieldsChanged.status) payload.status = status || null;
    if (status > 5 && fieldsChanged.status) payload.end_time = new Date().toISOString();

    if (Object.keys(payload).length === 0) {
      return; // Don't make API call if no fields were changed
    }

    adminHost.patch(`/api/exchanges/${id}/`, payload)
      .then((response) => {
        setChangesSaved(true)
      })
      .catch((e) => console.error(e));
  }

  return (
    <section className="modal">
      <article className="modal-content">
        <div className="exit-icon">
          <div className="div-2">Manual Update</div>
          <img
            src={Close}
            width={30}
            onClick={() => {
              onClose();
            }}
            alt=""
          />
        </div>
        <div className="div">
          <div style={{ display: "flex" }}>
            <div className="div-3">
              <div className="css-gebwvy-control">
                <div className="css-ValueContainer">
                  <div className="css-singleValue">
                    <select 
                      id="feedback-type" 
                      name="feedback-type" 
                      placeholder="Status" 
                      className="form-control style option" 
                      onChange={(e) => {
                        setStatus(+e.target.value);
                        setFieldsChanged(prev => ({...prev, status: true}));
                      }} 
                      defaultValue={item?.status} 
                      required
                    >
                      {statusList?.map(opt => 
                        <option value={opt.value}>{opt.label}</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>Deposit</label>
                    <div className="valueInpUpdate">
                      <input 
                        type="text" 
                        value={depId} 
                        placeholder="Deposit" 
                        onChange={(e) => {
                          setDepId(e.target.value);
                          setFieldsChanged(prev => ({...prev, depId: true}));
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_select_items">
                <span
                  onClick={() => {
                    adminHost.patch(`/api/exchanges/${item?.id}/`,
                      {
                        deposit_id: depId
                      })
                     .then((response) => {
                       setAlertIsOpen(true)
                     })
                     .catch((e) => console.error(e));
                  }}
                >
                  Add new ID +
                </span>
              </div>
            </div>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>Withdrawals</label>
                    <div className="valueInpUpdate">
                      <input 
                        type="text" 
                        value={withId} 
                        placeholder="Withdrawals" 
                        onChange={(e) => {
                          setWithId(e.target.value);
                          setFieldsChanged(prev => ({...prev, withId: true}));
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_select_items">
                <span
                  onClick={() => {
                    adminHost.patch(`/api/exchanges/${item?.id}/`,
                      {
                        withdraw_id: withId
                      })
                     .then((response) => {
                       setAlertIsOpen(true)
                     })
                     .catch((e) => console.error(e));
                  }}
                >
                  Add new ID +
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>Sell Trades</label>
                    <div className="valueInpUpdate">
                      <input 
                        type="text" 
                        value={sellId} 
                        placeholder="Sell Trades" 
                        onChange={(e) => {
                          setSellId(e.target.value);
                          setFieldsChanged(prev => ({...prev, sellId: true}));
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_select_items">
                <span
                  onClick={() => {
                    adminHost.patch(`/api/exchanges/${item?.id}/`,
                      {
                        order_sell_id: sellId
                      })
                     .then((response) => {
                       setAlertIsOpen(true)
                     })
                     .catch((e) => console.error(e));
                  }}
                >
                  Add new ID +
                </span>
              </div>
            </div>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                  <label style={{padding: "10px 0 0 0", color: "#fff"}}>Buy Trades</label>
                    <div className="valueInpUpdate">
                      <input 
                        className="" 
                        value={buyId} 
                        type="text" 
                        placeholder="Buy Trades" 
                        onChange={(e) => {
                          setBuyId(e.target.value);
                          setFieldsChanged(prev => ({...prev, buyId: true}));
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_select_items">
                <span
                  onClick={() => {
                    adminHost.patch(`/api/exchanges/${item?.id}/`,
                      {
                        order_buy_id: buyId
                      })
                     .then((response) => {
                       setAlertIsOpen(true)
                     })
                     .catch((e) => console.error(e));
                  }}
                >
                  Add new ID +
                </span>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>Node Deposit ID</label>
                    <div className="valueInpUpdate">
                      <input 
                        type="text" 
                        value={nodeDepId} 
                        placeholder="Node Deposit ID" 
                        onChange={(e) => {
                          setNodeDepId(e.target.value);
                          setFieldsChanged(prev => ({...prev, nodeDepId: true}));
                        }} 
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="order_select_items">
                <span
                  onClick={() => {
                    adminHost.patch(`/api/exchanges/${item?.id}/`,
                      {
                        node_deposit_id: nodeDepId
                      })
                     .then((response) => {
                       setAlertIsOpen(true)
                     })
                     .catch((e) => console.error(e));
                  }}
                >
                  Add new ID +
                </span>
              </div>
            </div>
          </div>
          {changesSaved ? (
            <span style={{ textAlign: "center", fontSize: "1rem", fontWeight: 600, fontFamily: "Montserrat", margin: "25px 0 10px" }}>Changes saved!</span>
          ) : (
            <button className="div-10" onClick={() => saveChanges(item?.id)}>Save</button>
          )}
        </div>
      </article>
      <style jsx>{`
        .modal {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          border: 1px solid #fff;
          width: 750px;
          background: linear-gradient(91deg, #7927ff 0%, #8b1ea6 100%);
          border: none;
          border-radius: 1.5rem;
        }
        .exit-icon {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 15px 20px 0 25px;
          align-items: center;
          font-size: 20px;
          margin-bottom: 20px;
        }
        .modal-title {
          color: #6821c3;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
        }
        .modal-image img {
          width: 8rem;
        }
        .modalText {
          text-align: justify;
        }
        .modal-button button {
          border: 1px solid #6821c3;
          padding: 6px;
          width: 8rem;
          background-color: #6821c3;
          color: white;
          border-radius: 7px;
        }
        .div {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          font-size: 11px;
          color: #fff;
          font-weight: 600;
          padding: 0 25px 20px;
        }
        .div-2 {
          font: 700 18px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
          color: #fff;
        }
        .div-3 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          border-color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.1);
          border-style: solid;
          border-width: 1px;
          margin-top: 9px;
          font-weight: 500;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .opened {
          width: 100%;
          padding: 0;
          margin: 0;
          margin-top: 7px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 0.6rem;
        }
        .hidden {
          display: none;
        }
        .order_select_items {
          display: block;
        }
        .div-7 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .order_select_items {
          display: flex;
          justify-content: center;
        }
        .order_select_items span {
          width: 100%;
          font-family: Montserrat, sans-serif;
          font-size: 1rem;
          border-radius: 0.6rem;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 15px 0;
          margin: 10px;
          text-align: center;
          cursor: pointer;
        }
        .div-8 {
          border-radius: 7px;
          backdrop-filter: blur(2px);
          background-color: rgba(108, 108, 108, 0.7);
          display: flex;
          margin-top: 7px;
          gap: 20px;
          white-space: nowrap;
          justify-content: space-between;
          padding: 12px 12px 12px 6px;
        }
        .div-9 {
          font-family: Montserrat, sans-serif;
        }
        .img {
          aspect-ratio: 1.43;
          object-fit: auto;
          object-position: center;
          width: 10px;
          fill: #fff;
          align-self: start;
        }
        .div-10 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.5rem;
          border: none;
          background-color: #27ae60;
          align-self: end;
          margin-top: 6px;
          white-space: nowrap;
          padding: 10px 25px;
          font-size: 1rem;
          color: #fff;
          font-weight: 600;
          margin: 20px 0 0 0;
          cursor: pointer;
        }
      `}</style>
      {alertIsOpen ? (
        <Alert
          onClose={() => {
            setAlertIsOpen(false);
          }}
          text="Saved!"
        />
      ) : null}
    </section>
  );
};

export default ManualUpdate;
